import { lazy } from "react";

const ListRequests = lazy(() => import("pages/ListRequests"));

const Users = lazy(() => import("pages/Users/UsersList"));
const AddUser = lazy(() => import("pages/AddUser"));

const Campaign = lazy(() => import("pages/Campaign"));
const CampaignDetails = lazy(() => import("pages/CampaignDetails"));

const List = lazy(() => import("pages/List"));
const ListDetails = lazy(() => import("pages/ListDetails"));

const Creator = lazy(() => import("pages/Creator"));
const AddCreator = lazy(() => import("pages/AddCreator"));
const CreatorDetails = lazy(() => import("pages/CreatorDetails"));

const Companies = lazy(() => import("pages/Companies"));
const AddCompany = lazy(() => import("pages/AddCompany"));

const CompanyDetails = lazy(() => import("pages/CompanyDetails"));

const Login = lazy(() => import("pages/Login"));

const Mails = lazy(() => import("pages/Mails"));
const Mail = lazy(() => import("pages/Mail"));
const Message = lazy(() => import("pages/EmailMessage"));
const Profile = lazy(() => import("pages/Profile"));

export { default } from "./AppRoutes";

export interface IRoute {
  path: string;
  element: JSX.Element;
}

export const public_routes: IRoute[] = [{ path: "/", element: <Login /> }];

export const private_routes: IRoute[] = [
  { path: "/list-requests", element: <ListRequests /> },

  { path: "/user", element: <Users /> },
  { path: "/add-user", element: <AddUser /> },
  { path: "/user-details/:_id", element: <AddUser /> },

  { path: "/company", element: <Companies /> },
  { path: "/add-company", element: <AddCompany /> },
  { path: "/update-company/:_id", element: <AddCompany /> },

  { path: "/company-details/:_id", element: <CompanyDetails /> },
  { path: "/mails", element: <Mails /> },
  { path: "/mails/:id", element: <Mail /> },
  { path: "/mails/:id/mail-details/:threadId", element: <Message /> },
];

export const client_routes: IRoute[] = [
  { path: "/profile", element: <Profile /> },

  { path: "/campaign", element: <Campaign /> },
  { path: "/campaign-details/:_id", element: <CampaignDetails /> },

  { path: "/list", element: <List /> },
  { path: "/list-details/:_id", element: <ListDetails /> },

  { path: "/creator", element: <Creator /> },
  { path: "/add-creator", element: <AddCreator /> },
  { path: "/add-creator/:listId", element: <AddCreator /> },
  { path: "/update-creator/:_id", element: <AddCreator /> },
  { path: "/creator-details/:_id", element: <CreatorDetails /> },
];

export const scraper_routes: IRoute[] = [
  { path: "/list-requests", element: <ListRequests /> },
];
