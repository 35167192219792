import http from "./http.service";
import Promisable from "./promisable.service";
import { companyActions } from "redux/slices/company";
import { getAppDispatch } from "utils/dispatch.util";
import { modalActions } from "redux/slices/modal/modalSlice";
import { NavigateFunction } from "react-router-dom";
import { formLoaderActions } from "redux/slices/formLoader";
import ToasterService from "utils/toaster.util";
import DateService from "utils/date.util";
import { campaignActions } from "redux/slices/campaign";

const url = "/company";

const CompanyService = {
  getCompany: async (id: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(companyActions.setLoading(true));
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/getOne/${id}`)
    );

    if (success) {
      const { company } = success?.data?.data || {};
      dispatch?.(companyActions.setCompany(company));
      dispatch?.(companyActions.setLoading(false));
    } else dispatch?.(companyActions.setCompany({ data: "Not Found" }));
    dispatch?.(formLoaderActions.setLoading(false));

    dispatch?.(companyActions.setLoading(false));
    return [success, error];
  },

  getCompanies: async (data?: any) => {
    const dispatch = getAppDispatch();

    if (data?.all) dispatch?.(modalActions.setLoading(true));
    else dispatch?.(companyActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/getAll`, data)
    );

    if (success) {
      const { companies, totalCount } = success?.data?.data || {};
      if (data?.all)
        dispatch?.(
          modalActions.updateData({
            table_data: companies.map((company: any) => ({
              Name: company.name,
              Campaign: company.campaign?.name,
              "Start Date": DateService.getServerDateString(company.startDate),
              Notes: company.notes,
            })),
          })
        );
      else
        dispatch?.(
          companyActions.setCompanies({ companies, count: totalCount })
        );
    }

    if (data?.all) dispatch?.(modalActions.setLoading(false));
    else dispatch?.(companyActions.setLoading(false));
    return [success, error];
  },

  getAll: async (data?: any) => {
    const dispatch = getAppDispatch();

    if (data?.all) dispatch?.(modalActions.setLoading(true));
    else dispatch?.(companyActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/getAll`, data)
    );

    if (success) {
      const { companies, totalCount } = success?.data?.data || {};
      if (data?.all)
        dispatch?.(
          modalActions.updateData({
            table_data: companies.map((company: any) => ({
              Name: company.name,
              Campaign: company.campaign?.name,
              "Start Date": DateService.getServerDateString(company.startDate),
              Notes: company.notes,
            })),
          })
        );
      else
        dispatch?.(
          companyActions.setCompanies({ companies, count: totalCount })
        );
    }

    if (data?.all) dispatch?.(modalActions.setLoading(false));
    else dispatch?.(companyActions.setLoading(false));
    return [success, error];
  },

  addCompany: async (data: any, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/create`, data)
    );

    if (success) {
      console.log("success?.data", success?.data);
      const { company } = success?.data?.data || {};
      dispatch?.(companyActions.addCompany(company));
    }
    navigate?.("/company");

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },

  updateCompany: async (id: string, data: any, navigate?: any) => {
    const dispatch = getAppDispatch();
    http.setJWT();
    dispatch?.(formLoaderActions.setLoading(true));

    const [success, error]: any = await Promisable.asPromise(
      http.patch(`${url}/${id}`, data)
    );
    if (success) {
      const { company } = success?.data?.data || {};
      dispatch?.(companyActions.setCompany(company));
      navigate?.("/company");
    }
    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },
  getAllCompanies: async () => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));
    dispatch?.(companyActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/getAll`)
    );

    if (success) {
      const { companies } = success.data.data;
      dispatch?.(companyActions.setCompanies({ companies, count: 0 }));
    }
    dispatch?.(companyActions.setLoading(false));

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
  deleteCompany: async (id: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.delete(`${url}/${id}`)
    );

    if (success) {
      dispatch?.(companyActions.deleteCompanyById(id));
      dispatch?.(modalActions.closeModal());
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
};

export default CompanyService;
