import * as React from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";

import List from "@mui/material/List";

import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { SiSpringCreators } from "react-icons/si";
import ListIcon from "@mui/icons-material/List";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { FaBuilding } from "react-icons/fa6";
import GroupIcon from "@mui/icons-material/Group";
import { useAppSelector } from "redux/hooks";
import AuthService from "services/auth.service";
import { IoMdGitPullRequest } from "react-icons/io";
import { ImMail2 } from "react-icons/im";
import { MdLogout } from "react-icons/md";
import { MdOutlineCampaign } from "react-icons/md";
import { BsBuildings } from "react-icons/bs";
import { LuUsers2 } from "react-icons/lu";
import { LuLayoutList } from "react-icons/lu";
import { LuMails } from "react-icons/lu";
import { ImProfile } from "react-icons/im";

import Logo from "assets/EKHOFINAL.png";
import { links } from ".";

const SectionTitle = ({ title }: { title: string }) => (
  <Box sx={{ display: "flex", paddingRight: "17px", alignItems: "center" }}>
    <Box
      sx={{
        width: "25px",
        height: "1px",

        backgroundColor: "rgba(58, 53, 65, 0.38)",
      }}
    />
    <p
      style={{
        padding: "0 10px",
        textTransform: "uppercase",
        color: "rgba(58, 53, 65, 0.38)",
        fontWeight: "500",
        fontSize: "0.75rem",
      }}
    >
      {title}
    </p>
    <Box
      sx={{ flex: 1, height: "1px", backgroundColor: "rgba(58, 53, 65, 0.38)" }}
    />
  </Box>
);

export const icons: any = {
  Campaigns: <MdOutlineCampaign size={28} />,
  Lists: <LuLayoutList size={25} />,
  Creators: <SiSpringCreators size={22} />,
  "List Requests": <IoMdGitPullRequest size={25} />,
  Users: <LuUsers2 size={24} />,
  Companies: <BsBuildings size={25} />,
  Mailbox: <LuMails size={25} />,
};

const drawerWidth = 260;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  border: "none",
  "& .MuiTypography-root": {
    fontSize: "16px",
    fontWeight: 400,
  },
  "& .MuiListItemButton-root": {
    borderTopRightRadius: "50px",
    borderBottomRightRadius: "50px",
  },
  // boxSizing: "border-box",
  "& .MuiListItemButton-root:hover": {
    boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    backgroundImage: "linear-gradient(98deg, #c6a7fe, #9155fd 94%)",
    color: "white",
  },
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer({
  children,
}: {
  children: React.ReactNode;
}) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const useStyles = makeStyles({
    paper: {
      background: "transparent",

      border: "none",
    },
  });
  const styles = useStyles();
  //   classes={{ paper: styles.paper }}

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isActive = (link: string) => {
    // Exact match for base routes
    if (pathname === link) return true;

    // Prefix match for sub-routes under /list and /list-requests
    if (link === "/list" && pathname.startsWith("/list-details")) return true;
    if (
      link === "/list-requests" &&
      (pathname.startsWith("/add-list-request") ||
        pathname.startsWith("/update-list-request"))
    )
      return true;

    return false;
  };
  const user = useAppSelector((state) => state.auth.user);

  if (!user || pathname === "/") return <>{children}</>;

  return (
    <Box sx={{ display: "flex" }}>
      <Drawer variant="permanent" open={open} classes={{ paper: styles.paper }}>
        <DrawerHeader
          sx={{
            justifyContent: "flex-start",
            mt: "5px",
            mx: "5px",
            color: "black",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <img
            src={Logo}
            style={{
              width: "100%",
              height: "100%",
              maxHeight: "44px",
              maxWidth: "48px",
            }}
            alt=""
          />{" "}
          <h1 style={{ marginBottom: 0, marginTop: "4px" }}>Ekho Inc.</h1>
        </DrawerHeader>

        <List sx={{ mt: 2 }}>
          <SectionTitle title="Dashboard Overview" />

          {links(user)?.map((item: any, index: number) => (
            <React.Fragment key={index}>
              <ListItem
                disablePadding
                sx={{
                  display: "block",
                  paddingRight: "15px",
                  marginBottom: "10px",
                  fontSize: "16px",
                }}
              >
                <ListItemButton
                  sx={{
                    height: 44,
                    justifyContent: "center",
                    alignItems: "center",
                    px: 2.5,
                  }}
                  className={isActive(item.link) ? `menu active` : "menu"}
                  onClick={() => navigate(item.link)}
                >
                  <div
                    style={{
                      width: "40px",
                      marginTop: "6px",
                      // fontSize: "25px",
                    }}
                  >
                    {icons[item.title]}
                  </div>
                  <ListItemText
                    primary={item.title}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
              {item.title == "Creators" && (
                <SectionTitle title="Account Management" />
              )}
            </React.Fragment>
          ))}

          <ListItem
            disablePadding
            sx={{
              display: "block",
              paddingRight: "15px",
              marginBottom: "10px",
              fontSize: "16px",
            }}
          >
            <ListItemButton
              sx={{
                height: 44,
                justifyContent: "center",
                alignItems: "center",
                px: 2.5,
              }}
              className={isActive("/profile") ? `menu active` : "menu"}
              onClick={() => navigate("/profile")}
            >
              <div
                style={{
                  width: "40px",
                  marginTop: "6px",
                  // fontSize: "25px",
                }}
              >
                <ImProfile size={24} />
              </div>
              <ListItemText
                primary={"profile"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            sx={{
              display: "block",
              paddingRight: " 15px",
              marginBottom: "10px",
              fontSize: "16px",
            }}
          >
            <ListItemButton
              sx={{
                height: 44,
                justifyContent: "center",
                alignItems: "center",
                px: 2.5,
              }}
              onClick={() => AuthService.logout()}
            >
              <div
                style={{
                  width: "40px",
                  marginTop: "6px",
                  marginLeft: "3px",
                }}
              >
                <MdLogout size={25} />
              </div>
              <ListItemText primary={"Logout"} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: "10px 20px 0px 20px" }}>
        {/* <DrawerHeader /> */}
        {children}
      </Box>
    </Box>
  );
}
